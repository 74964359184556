<template>
  <el-drawer
      class="drawerCommon"
      :visible.sync="drawer"
      size="600px" title="实时监控">
    <el-tabs v-model="cageId" class="tabsCage" @tab-click="clickTab">
      <el-tab-pane
          :key="item.id"
          v-for="(item, index) in tabs"
          :label="item.number"
          :name="item.id"
      >
        <!-- <video src="" width="100%" height="400" controls="controls"></video> -->
        <camera :data-list="cameraList"></camera>
        <div class="cage" v-for="(obj,ind) in list" :key="ind">
          <strong v-if="obj.orderDetail">{{ obj.orderDetail.gender == 0 ? '父本' : '母本' }}</strong>
          <div class="info">
            <span v-if="obj.orderDetail">耳号：{{ obj.orderDetail.overbit }}</span>
            <span v-if="obj.orderDetail">出生日期：{{ obj.updateTime | formatDay }}</span>
            <span v-if="obj.orderDetail">周龄：{{ obj.orderDetail.weekDays }}周</span>
            <p v-if="obj.orderDetail">基因类型：<span v-for="type in obj.orderDetail.gene">{{ type.type }}  </span></p>
            <p>备注信息：{{ obj.remark }}</p>
          </div>
          <div class="cage" v-if="obj.mateBirthList.length" v-for="(brith,i) in obj.mateBirthList">
            <strong>新生动物</strong>
            <div class="info">
              <span>雌性数量：{{brith.femaleNum}} </span>
              <span>雄性数量：{{brith.maleNum}} </span>
            </div>
          </div>
        </div>
        
      </el-tab-pane>
    </el-tabs>
  </el-drawer>

</template>

<script>
import camera from '@/components/common/camera'

export default {
  name: "realtimeImage",
  components: {
    camera
  },
  data() {
    return {
      drawer: false,
      tabsValue: '',
      tabs: ['',''],
      cageId:0,//笼舍id
      data:{},
      list: []
    }
  },
  computed: {
    cameraList() {
      return [
        {name: '', devId: this.data.divId}
      ]
    }
  },
  mounted() {
    window.addEventListener('beforeunload', e => this.beforeunload(e))
  },
  methods: {
    beforeunload(e) {
      // 检测到页面重载事件，直接断开websocket
      this.$store.dispatch('websocket/WEBSOCKET_CLOSE')
    },
    //根据笼舍，获取动物数据
    getData(){
      // 接口添加
      this.$get("/subject/cage/watch/"+this.cageId).then(res => {
        this.data = res.data;
        res.data.mateAniCageList.forEach((item,index) => {
          if(item.orderDetail){
            item.orderDetail.gene = JSON.parse(item.orderDetail.gene)
          }else {
            item.orderDetail = item.aniQuarResult;
            item.orderDetail.gene = JSON.parse(item.aniQuarResult.gene)
          }
        })
        this.list = res.data.mateAniCageList;
      }).catch(() => {
        // this.$message.error("操作失败，请重试！")
      })
    },
    //tab,切换
    clickTab(ev){
      this.getData();//加载数据
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";
</style>
